import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, User, initialContentLoading } from '@ea/common';
import { getCookie } from '../../../service/helpers/cookie';
import { parseJSON } from '../../../service/helpers/json';
import { AuthActions } from './actions';

const initialValue = () => {
	return {
		user: initialContentLoading(parseJSON(getCookie('auth_data'))),
		token: getCookie('auth_token'),
	};
};

export interface AuthState {
	user: ContentLoading<User | undefined>;
	token?: string;
}

const initialState: AuthState = initialValue();

export const AuthReducer = reducerWithInitialState(initialState)
	.case(AuthActions.getInfo.started, (state) => ({
		...state,
		user: {
			...state.user,
			loading: {
				status: 'loading' as const,
			},
		},
	}))
	.case(AuthActions.getInfo.done, (state, { result }) => ({
		...state,
		user: {
			...state.user,
			content: result,
			loading: {
				status: 'loaded' as const,
			},
		},
	}))
	.case(AuthActions.logout.done, () => {
		return {
			token: undefined,
			user: initialContentLoading(undefined),
		};
	});
