import React, { FC } from 'react';
import { beautifyNumber } from '@ea/common';
import styled from 'astroturf';
import { isNumber } from 'lodash-es';

interface Props {
	id: unknown;
}

export const Identifier: FC<Props> = ({ id }) => {
	return <Content>{isNumber(id) ? beautifyNumber(id) : '-'}</Content>;
};

const Content = styled.div`
	font-size: 24px;
	height: 24px;
	overflow: hidden;
	font-weight: lighter;
`;
