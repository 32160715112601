import { useEffect, useRef } from 'react';

export function useKeyPress(key: string, handler?: () => void) {
	const handlerRef = useRef(handler);

	const internalHandler = (event: KeyboardEvent) => {
		if (event.key === key) {
			if (handlerRef.current) {
				handlerRef.current();
			}
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', internalHandler);
		return () => {
			document.removeEventListener('keydown', internalHandler);
		};
	}, []);
}
