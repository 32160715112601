import { callApi } from '../../apiActionsSync';
import { AuthActions } from './actions';

export const AuthAsync = {
	getInfo: () =>
		callApi({
			url: 'getInfo',
			method: 'GET',
			params: {},
			noErrorToast: true,
			actions: AuthActions.getInfo,
			prefix: `${process.env.REACT_APP_AUTH_SERVICE}/api`,
		}),
	logout: () =>
		callApi({
			url: 'logout/',
			method: 'POST',
			params: {},
			actions: AuthActions.logout,
			prefix: `${process.env.REACT_APP_AUTH_SERVICE}/api`,
		}),
};
