import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'astroturf';
import { useDebouncedCallback } from 'use-debounce';
// Workaround, because ColorWrap is not exported in index.d.ts
import { ColorState } from 'react-color';
import useOnClickOutside from 'use-onclickoutside';
import { DarkWrappedPicker, LightWrappedPicker, WrappedPicker } from './Pickers';

export type ColorType = 'dark' | 'light' | 'all';

interface Props {
	color?: string;
	onChange?: (color: string) => void;
	onClose?: () => void;
	debounce?: boolean;
	type?: ColorType;
}

export const ColorPicker: FC<Props> = ({ color, onChange, onClose, debounce, type = 'all' }) => {
	const [internalColor, setInternalColor] = useState(color);

	useEffect(() => {
		setInternalColor(color);
	}, [color, setInternalColor]);

	const [debouncedOnChange] = useDebouncedCallback((color: string) => {
		if (onChange) {
			onChange(color);
		}
	}, 250);

	const onChangeColor = useCallback(
		({ hex }: ColorState) => {
			setInternalColor(hex);
			if (debounce) {
				debouncedOnChange(hex);
			} else if (onChange) {
				onChange(hex);
			}
		},
		[debounce, onChange, setInternalColor, debouncedOnChange],
	);

	const element = useRef<HTMLDivElement>(null);

	useOnClickOutside(element, (event) => {
		const target = event.target as HTMLElement;
		// TODO: жёсткий костыль, чтобы предотвратить закрытие при нажатии на Color-элементы
		if (target.className && target.className.indexOf && target.className.indexOf('Color') === -1) {
			onClose && onClose();
		}
	});

	const Picker = type === 'dark' ? DarkWrappedPicker : type === 'light' ? LightWrappedPicker : WrappedPicker;

	return (
		<Container ref={element}>
			<Picker onChange={onChangeColor} color={internalColor} />
		</Container>
	);
};

const Container = styled.div`
	height: 100%;
	user-select: none;
`;
