import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import { values, includes } from 'lodash-es';
import { AuthSelectors } from '../../../store/general/auth/selectors';
import { Site } from '../../../config/general';
import { routes } from '../../../core/routes/paths';

interface Props extends RouteProps {
	site?: Site;
	roles?: string[];
}

export const ProtectedRoute: FC<Props | RouteProps> = (props) => {
	const { site, roles, ...routerProps } = props as Props;

	const user = useSelector(AuthSelectors.user);

	const token = useSelector(AuthSelectors.token);

	const redirectTo = process.env.REACT_APP_AUTH_SERVICE;

	const isAuthorized = !!token;

	let isAllowed = isAuthorized;

	if (isAuthorized) {
		if (site) {
			if (user.content && user.content[site]) {
				isAllowed = roles ? user.content.roles.some((role) => includes(roles, role)) : true;
			}
		}
	}

	if (isAuthorized) {
		if (isAllowed) {
			return <Route {...routerProps} />;
		} else {
			return <Redirect to={routes.root.path} />;
		}
	} else {
		if (redirectTo) {
			window.location.href = redirectTo;
		}
		return null;
	}
};
