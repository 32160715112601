import React, { FC } from 'react';
import styled from 'astroturf';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash-es';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../images/icons/actions/edit.svg';
import { ReactComponent as LinkIcon } from '../../images/icons/actions/link.svg';
import { beautifyNumber } from '../../service/utils/html';
import { TextButton } from '../elements/buttons/TextButton';
import { TextEllipsisWithTooltip } from '../service/TextEllipsisWithTooltip';
import { EstimateScore } from './EstimateScore';

interface Props {
	fullName: string;
	className?: string;
	personalArea?: string;
	id?: number;
	isWorkerProfileCard?: boolean;
	score?: number;
	onEdit?: () => void;
	editable?: boolean;
	fields?: {
		label: string;
		value: string | number | null;
	}[];
}

export const ProfileCard: FC<Props> = ({
	className,
	onEdit,
	editable,
	id,
	score,
	personalArea,
	isWorkerProfileCard,
	fullName = '',
	fields = [],
}) => {
	const { t } = useTranslation();

	return (
		<Wrapper className={className}>
			<Header>
				<ProfileName>
					{fullName.split(' ').map((item) => (
						<TextEllipsisWithTooltip key={item}>{item}</TextEllipsisWithTooltip>
					))}
				</ProfileName>
				{editable && (
					<EditButton icon={<EditIcon />} onClick={onEdit}>
						{t('common.actions.edit')}
					</EditButton>
				)}
			</Header>

			{personalArea && (
				<Link to={{ pathname: personalArea }} target={'_blank'} rel={'noopener noreferrer'}>
					<PersonalAreaLink icon={<LinkIcon />}>{t('common.actions.openPersonalArea')}</PersonalAreaLink>
				</Link>
			)}

			<InfoTable>
				{isNumber(id) && (
					<IdentifierRow>
						<RowTitle>{t('common.words.identifier')}</RowTitle>
						<RowContent>
							<Identifier>{beautifyNumber(id)}</Identifier>
						</RowContent>
					</IdentifierRow>
				)}
				{fields
					.filter((el) => el.value || isNumber(el.value))
					.map((field) => (
						<Row key={field.label}>
							<RowTitle bold>{field.label}</RowTitle>
							<RowContent text={field.value as string} />
						</Row>
					))}

				{score !== null && isWorkerProfileCard && (
					<Row>
						<RowTitle bold>{t('words.score')}</RowTitle>
						<RowContent>
							<EstimateScore value={score} />
						</RowContent>
					</Row>
				)}
			</InfoTable>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 33px 10px 23px 0;
`;

const Identifier = styled.div`
	font-size: 24px;
	height: 24px;
	font-weight: lighter;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const StyledTextButton = styled(TextButton)``;
const InfoTable = styled.div`
	margin-top: 2px;
	font-size: 12px;
`;

const ProfileName = styled.h2`
	font-size: 24px;
	font-weight: bold;
	margin-top: 0;
	margin-left: 22px;
	overflow: hidden;
	line-height: 32px;
	margin-bottom: 18px;
	word-wrap: break-word;
`;

const IdentifierRow = styled.div`
	display: flex;
	align-items: flex-end;
	margin-bottom: 28px;
`;

const Row = styled.div`
	display: flex;
	box-sizing: content-box;
	line-height: 24px;
	align-items: center;
`;

const RowTitle = styled(TextEllipsisWithTooltip)<{ bold?: boolean }>`
	padding-right: 24px;
	vertical-align: baseline;
	text-align: right;
	width: 135px;
	flex-shrink: 0;
	white-space: nowrap;
	&.bold {
		font-weight: bold;
	}
`;
const EditButton = styled(StyledTextButton)`
	margin-top: 3px;
`;

const RowContent = styled(TextEllipsisWithTooltip)`
	width: 100%;
	min-width: 0;
	vertical-align: baseline;
`;

const PersonalAreaLink = styled(StyledTextButton)`
	margin-left: 16px;
	margin-top: -16px;
`;
