export interface SkillShort {
	id: number;
	grade: number;
	grpName: string;
	name: string;
	complexity: number;
}

export interface PersonSkillShort extends SkillShort {
	skillId: number;
}

interface SkillDetailBase {
	id: number;
	type: SkillType;
	name: string;
	personTeamSkillId: number;
	company: number;
	fact: number;
	goal: number;
	coverage: number;
	grp: {
		id: number;
		name: string;
	};
	positions: SkillDetailProperty[];
	isDelete: boolean;
}

export interface SkillDetail extends SkillDetailBase {
	fte: number;
	grade: number;
	complexity: number;
	knowledge: SkillDetailProperty[];
	abilities: SkillDetailProperty[];
}

export interface SecondSkillDetail extends SkillDetailBase {
	monthCount: number;
	description: string;
}

export interface AccessDetail extends SkillDetailBase {
	monthCount: number;
	fte: number;
}

export interface SkillDetailProperty {
	id: number;
	name: string;
}

export type SkillPosition = {
	id: number;
	positionName: string;
	sp1Name: string;
	sp2Name: string;
};

export type Position = {
	id: number;
	name: string;
	sp1Name: string;
	sp2Name: string;
};

export type SkillPerson = {
	id: number;
	fio: string;
	grade: number;
	level: number;
	skillId: number;
	team: number;
	teamName: string;
};

export type SkillTeam = {
	id: number;
	name: string;
	persons: SkillPerson[];
};

export type SkillTraining = {
	id: number;
	name: string;
};

export enum SkillType {
	Main = 1,
	Second,
	Access,
}

export enum SkillTypeCode {
	Main = 'skills',
	Second = 'skills2',
	Access = 'access',
}

export interface TrainingDetail {
	date: string;
	notes: string;
	year: number;
	author: string;
	isDelete: boolean;
	document: string;
	typeName: string;
	program: string;
	type: number;
	categoryName: string;
	name: string;
	company: number;
	id: number;
	category: number;
}

export interface TrainingPlan {
	sqn: number;
	hours: number;
	trainingId: number;
	created: string;
	isDelete: boolean;
	name: string;
	id: number;
	isActive: boolean;
	updated: string;
	selfHours: number;
	deactivated: null;
}

export interface TrainingTopic {
	topicId: number;
	trainingId: number;
	programName: string;
	topic: string;
	topicText: string;
	programId: number;
}

export interface TrainingProgram {
	topics: TrainingTopic[];
	name: string;
	id: number;
}

export interface TrainingAttribute {
	name: string;
	value: string;
	id: number;
	attributeId: number;
}

export interface TrainingSkill {
	name: string;
	id: number;
}

export interface TrainingBibliography {
	sqn: number;
	trainingId: number;
	created: string;
	isDelete: boolean;
	name: string;
	id: number;
	isActive: boolean;
	updated: string;
	deactivated: null;
}

export interface TrainingFiles {
	id: number;
	name: string;
	size: number;
}

export enum PageVariation {
	Foreman = 'leader',
	Admin = 'hr',
}

export interface Structure {
	id: number;
	name: string;
}
