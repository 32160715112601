import { forEach } from 'lodash-es';
import { Dict } from '../types';

export function isTextFilter(field: string) {
	return (
		(field[0] === 's' && field[1] !== 'p') ||
		field[0] === 'n' ||
		field === 'event' ||
		field === 'quality' ||
		field === 'counter' ||
		field === 'level' ||
		field === 'fio' ||
		field === 'scoreFilter'
	);
}
export function isDateFilter(field: string) {
	return field === 'startDate' || field === 'finishDate';
}

export function filterValuesToQuery(obj: any) {
	const result = {} as Dict<string>;
	forEach(obj, (value: string[], key) => {
		if (value.length) {
			result[key] = Array.isArray(value)
				? value
						.map((el: any) => (el.id ? el.id : (el.name ? el.name.replace(/\n/g, '%0A') : el) || el))
						.join(',')
				: value;
		}
	});
	return result;
}
