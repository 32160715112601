import { isString } from 'lodash-es';

export function parseJSON(value: unknown) {
	try {
		if (isString(value)) {
			return JSON.parse(value);
		}
	} catch {
		return undefined;
	}
	return undefined;
}
