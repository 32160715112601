import { mapValues } from 'lodash-es';
import { Dict } from '@ea/common';
import { Row } from '../../typings/lists';
import { ListGroup, ListRow, NormalizedList } from './typings';

export function joinRows<Item extends object, List extends NormalizedList<Item>>(
	data: List,
	{ rows: newRows, ...newData }: NormalizedList<Item>,
	stub = false,
) {
	const rows = newRows.filter((row) => {
		const element = data[row.type][row.id];
		return row.type && (!element || (element as { stub: boolean }).stub);
	});

	const fields = ['groups', 'subgroups', 'items'] as const;

	const result = fields.reduce<Dict>(
		(result, field) => ({
			...result,
			[field]: stub
				? {
						...mapValues(newData[field], (value) => ({ ...value, stub: true })),
						...data[field],
				  }
				: {
						...data[field],
						...mapValues(newData[field], (value) => ({ ...value, stub: false })),
				  },
		}),
		{},
	);

	return {
		...data,
		rows: [...data.rows, ...rows],
		...result,
	};
}

export function filterRows(rows: ListRow[], condition: (row: ListRow) => boolean) {
	return rows
		.filter((row) => !condition(row))
		.filter(
			(row, index, rows) =>
				rows[index].type !== 'subgroups' || (index + 1 < rows.length && rows[index + 1].type === 'items'),
		)
		.filter(
			(row, index, rows) =>
				rows[index].type !== 'groups' || (index + 1 < rows.length && rows[index + 1].type !== 'groups'),
		);
}

export function normalize<Item extends { id: number }>(groups: ListGroup<Item>[]) {
	const result: NormalizedList<Item> = {
		groups: {},
		subgroups: {},
		items: {},
		rows: [],
	};

	(groups || []).forEach((group) => {
		const { subgroups, ...groupData } = group;

		const groupId = groupData.id;

		if (groupId) {
			result.groups[groupId] = groupData;
			result.rows.push({
				type: 'groups',
				id: groupId,
			});

			(subgroups || []).forEach(({ items, ...sectionData }) => {
				const subgroupId = `${groupData.id}/${sectionData.id}`;

				result.subgroups[subgroupId] = { ...sectionData, id: subgroupId };
				result.rows.push({
					type: 'subgroups',
					id: subgroupId,
					groupId,
				});

				(items || []).forEach((profile) => {
					result.items[profile.id || (profile as any).skill] = profile;
					result.rows.push({
						type: 'items',
						groupId,
						subgroupId,
						id: profile.id || (profile as any).skill,
					});
				});
			});
		}
	});

	return result;
}
