import { flatten, mapKeys, mapValues, omit, reduce, uniq, values } from 'lodash-es';
import { Dict } from '@ea/common';
import { arrayToObject, countTruthy } from '../../../../core/services/utils';
import { SelectionByGroups } from './typings';
import { GetStatisticsByGroupsResponse } from './api';

export function extendSelection(currentSelection: SelectionByGroups, statistics: GetStatisticsByGroupsResponse) {
	return {
		...currentSelection,
		groups: {
			...currentSelection.groups,
			...mapValues(statistics.groups, (value, groupId) => {
				const subgroups = mapKeys(value.subgroups, (_, subgroupId) => `${groupId}/${subgroupId}`);
				const rows = uniq(flatten(values(value.subgroups).map((el) => el.items)));

				return {
					selected: {
						...arrayToObject(rows, false),
						...currentSelection.groups[groupId]?.selected,
					},
					total: rows.length,
					subgroups: mapValues(subgroups, (value) => {
						return {
							selected: arrayToObject(value.items, false),
							total: value.total,
						};
					}),
				};
			}),
		},
	};
}

export function countSelected(data?: { selected: Dict<boolean>; total: number }) {
	if (data) {
		const { selected, total } = data;
		const selectedCount = countTruthy(selected);
		return {
			indeterminate: selectedCount > 0 && selectedCount !== total,
			selected: selectedCount > 0 && selectedCount === total,
			total,
		};
	} else {
		return {
			indeterminate: false,
			selected: false,
			total: 0,
		};
	}
}

interface Selection<T extends string | number> extends SelectionByGroups {
	count: number;
	ids: T[];
}

export function getSelectionStatistics(selection: SelectionByGroups, numeric: false): Selection<string>;
export function getSelectionStatistics(selection: SelectionByGroups, numeric?: true): Selection<number>;
export function getSelectionStatistics(selection: SelectionByGroups, numeric = true): Selection<number | string> {
	const ids = reduce(
		selection.rows,
		(result, selected, id) => (selected ? result.concat(numeric ? Number(id) : id) : result),
		[] as (number | string)[],
	);
	return {
		...selection,
		count: ids.length,
		ids,
	};
}

export function shrinkSelection(selection: Partial<SelectionByGroups>, params: number[]) {
	const result = {} as SelectionByGroups;
	if (selection.rows) {
		result.rows = omit(selection.rows, params) as Record<string, boolean>;
	}
	if (selection.rows) {
		result.groups = mapValues(selection.groups, (group) => {
			if (group) {
				const selected = omit(group.selected, params) as Record<string, boolean>;
				return {
					selected,
					total: group.total - (countTruthy(group.selected) - countTruthy(selected)),
					subgroups: mapValues(group.subgroups, (value) => {
						if (value) {
							const selected = omit(value.selected, params) as Record<string, boolean>;
							return {
								selected,
								total: value.total - (countTruthy(value.selected) - countTruthy(selected)),
							};
						}
						return value;
					}),
				};
			}
			return group;
		});
	}
	return result;
}
