import { isNumber } from 'lodash-es';
import { foremanConfig } from '../../config/foreman';
import { SkillType, SkillTypeCode } from '../../service/typings/workers/common/entities';
import { routes } from './paths';

const prefix = foremanConfig.routePrefix;

export const foremanRoutes = {
	root: { path: `${prefix}/`, link: () => `${prefix}` },
	skills: {
		list: { path: `${prefix}/:companyId/library`, link: (companyId: number) => `${prefix}/${companyId}/library` },
		trash: {
			path: `${prefix}/:companyId/library/trash`,
			link: (companyId: number) => `${prefix}/${companyId}/library/trash`,
		},
		create: {
			path: `${prefix}/:companyId/library/create`,
			link: (companyId: number) => `${prefix}/${companyId}/library/create`,
		},
	},
	firstSkill: {
		root: {
			path: `${prefix}/:companyId/library/main-skill/:skillId`,
			link: (companyId: number, id: number) => `${prefix}/${companyId}/library/main-skill/${id}`,
		},
		persons: {
			root: {
				path: `${prefix}/:companyId/library/main-skill/:skill/persons`,
				link: (companyId: number, id: number) => `${prefix}/${companyId}/library/main-skill/${id}/persons`,
			},
			trash: {
				path: `${prefix}/:companyId/library/main-skill/:skill/persons/trash`,
				link: (companyId: number, id: number) =>
					`${prefix}/${companyId}/library/main-skill/${id}/persons/trash`,
			},
		},
	},
	secondSkill: {
		root: {
			path: `${prefix}/:companyId/library/second-skill/:skillId`,
			link: (companyId: number, id: number) => `${prefix}/${companyId}/library/second-skill/${id}`,
		},
		persons: {
			root: {
				path: `${prefix}/:companyId/library/second-skill/:skill/persons`,
				link: (companyId: number, id: number) => `${prefix}/${companyId}/library/second-skill/${id}/persons`,
			},
			trash: {
				path: `${prefix}/:companyId/library/second-skill/:skill/persons/trash`,
				link: (companyId: number, id: number) =>
					`${prefix}/${companyId}/library/second-skill/${id}/persons/trash`,
			},
		},
	},
	access: {
		root: {
			path: `${prefix}/:companyId/library/access/:skillId`,
			link: (companyId: number, id: number) => `${prefix}/${companyId}/library/access/${id}`,
		},
		persons: {
			root: {
				path: `${prefix}/:companyId/library/access/:skill/persons`,
				link: (companyId: number, id: number) => `${prefix}/${companyId}/library/access/${id}/persons`,
			},
			trash: {
				path: `${prefix}/:companyId/library/access/:skill/persons/trash`,
				link: (companyId: number, id: number) => `${prefix}/${companyId}/library/access/${id}/persons/trash`,
			},
		},
	},
	persons: {
		card: {
			path: `${prefix}/:companyId/persons/:personId`,
			link: (companyId: number, personId: number) => `${prefix}/${companyId}/persons/${personId}`,
		},
		list: { path: `${prefix}/:companyId/persons/`, link: (companyId: number) => `${prefix}/${companyId}/persons` },
		trash: {
			path: `${prefix}/:companyId/persons/trash`,
			link: (companyId: number) => `${prefix}/${companyId}/persons/trash`,
		},
	},
};

export function getSkillRoute(type: SkillTypeCode | SkillType) {
	if (isNumber(type)) {
		if (type === SkillType.Main) {
			return foremanRoutes.firstSkill;
		} else if (type === SkillType.Second) {
			return foremanRoutes.secondSkill;
		} else {
			return foremanRoutes.access;
		}
	} else {
		if (type === SkillTypeCode.Main) {
			return foremanRoutes.firstSkill;
		} else if (type === SkillTypeCode.Second) {
			return foremanRoutes.secondSkill;
		} else {
			return foremanRoutes.access;
		}
	}
}

export function getForemanPersonLink(companyId: number, skillId: number, type: number) {
	if (skillId) {
		if (type === SkillType.Main) {
			return routes.foreman.firstSkill.persons.root.link(companyId, skillId);
		} else if (type === SkillType.Second) {
			return routes.foreman.secondSkill.persons.root.link(companyId, skillId);
		} else if (type === SkillType.Access) {
			return routes.foreman.access.persons.root.link(companyId, skillId);
		}
	}
}

export function getForemanSkillLink(companyId: number, skillId: number, type: number) {
	if (skillId) {
		if (type === SkillType.Main) {
			return routes.foreman.firstSkill.root.link(companyId, skillId);
		} else if (type === SkillType.Second) {
			return routes.foreman.secondSkill.root.link(companyId, skillId);
		} else if (type === SkillType.Access) {
			return routes.foreman.access.root.link(companyId, skillId);
		}
	}
}
