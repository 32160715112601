import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useSpecialistsProfile } from '../../../hooks/specialists/useSpecialistsProfile';
import { routes } from '../../../core/routes/paths';

export const SpecialistGuard: FC<RouteProps> = (props) => {
	const profile = useSpecialistsProfile();

	if (profile.isActive) {
		return <Route {...props} />;
	} else if (profile.loaded) {
		return <Redirect to={routes.root.path} />;
	} else {
		return null;
	}
};
