import React, { FC } from 'react';
import styled from 'astroturf';

interface Props {
	value?: string | number;
	className?: string;
}

function getScoreColor(score: number) {
	if (score >= 0.9) {
		return '#8bc34a';
	}
	if (score >= 0.7 && score <= 0.89) {
		return '#d9ecc4';
	}
	if (score >= 0.5 && score <= 0.69) {
		return '#fff9be';
	}
	if (score >= 0 && score <= 0.49) {
		return '#fac7c6';
	}
	return '#ffffff';
}

export const EstimateScore: FC<Props> = ({ value, className }) => {
	return (
		<Body className={className} style={{ background: getScoreColor(Number(value)) }}>
			{value}
		</Body>
	);
};

const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 2px;

	width: 72px;
	height: 30px;
	border-radius: 2px;

	font-size: 24px;
	font-weight: 300;
`;
