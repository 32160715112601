import { combineReducers } from 'redux';
import { SkillsListReducer } from './skills/reducer';
import { SkillPersonsReducer } from './persons/reducer';
import { TeamReducer } from './team/reducer';

export const ListsReducer = combineReducers({
	skills: SkillsListReducer,
	persons: SkillPersonsReducer,
	team: TeamReducer,
});
