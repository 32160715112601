import { actionCreatorFactory } from 'typescript-fsa';
import {
	CreateTrainingRequest,
	GetDefaultTrainingAttributesRequest,
	GetDefaultTrainingAttributesResponse,
	GetTrainingAttributesRequest,
	GetTrainingAttributesResponse,
	GetTrainingBibliographyRequest,
	GetTrainingBibliographyResponse,
	GetTrainingFilesRequest,
	GetTrainingFilesResponse,
	GetTrainingPlanRequest,
	GetTrainingPlanResponse,
	GetTrainingProgramRequest,
	GetTrainingProgramResponse,
	GetTrainingRequest,
	GetTrainingResponse,
	GetTrainingSkillsRequest,
	GetTrainingSkillsResponse,
	UpdateTrainingRequest,
	DeleteTrainingFilesRequest,
	DeleteTrainingFilesResponce,
	GetCategoryListResponce,
	GetCategoryListRequest,
	GetTypeListRequest,
	GetTypeListResponce,
	GetTrainingSkillsListResponse,
	GetTrainingSkillsListRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('WORKERS/ADMIN_TRAINING_DETAILS');

export const TrainingDetailsActions = {
	getTraining: ActionFactory.async<GetTrainingRequest, GetTrainingResponse, Error>('GET_TRAINING'),
	updateTraining: ActionFactory.async<UpdateTrainingRequest, any, Error>('UPDATE_TRAINING'),
	createTraining: ActionFactory.async<CreateTrainingRequest, any, Error>('CREATE_TRAINING'),
	getPlan: ActionFactory.async<GetTrainingPlanRequest, GetTrainingPlanResponse, Error>('GET_PLAN'),
	getProgram: ActionFactory.async<GetTrainingProgramRequest, GetTrainingProgramResponse, Error>('GET_PROGRAM'),
	getBibliography: ActionFactory.async<GetTrainingBibliographyRequest, GetTrainingBibliographyResponse, Error>(
		'GET_BIBLIOGRAPHY',
	),
	getAttributes: ActionFactory.async<GetTrainingAttributesRequest, GetTrainingAttributesResponse, Error>(
		'GET_ATTRIBUTES',
	),
	getDefaultAttributes: ActionFactory.async<
		GetDefaultTrainingAttributesRequest,
		GetDefaultTrainingAttributesResponse,
		Error
	>('GET_DEFAULT_ATTRIBUTES'),
	getFiles: ActionFactory.async<GetTrainingFilesRequest, GetTrainingFilesResponse, Error>('GET_FILES'),
	getSkills: ActionFactory.async<GetTrainingSkillsRequest, GetTrainingSkillsResponse, Error>('GET_SKILLS'),
	getSkillsList: ActionFactory.async<GetTrainingSkillsListRequest, GetTrainingSkillsListResponse, Error>(
		'GET_SKILLS_LIST',
	),
	deleteFile: ActionFactory.async<DeleteTrainingFilesRequest, DeleteTrainingFilesResponce, Error>('DELETE_FILE'),
	getCategoryList: ActionFactory.async<GetCategoryListRequest, GetCategoryListResponce, Error>('GET_CATEGORY_LIST'),
	getTypeList: ActionFactory.async<GetTypeListRequest, GetTypeListResponce, Error>('GET_TYPE_LIST'),
};
