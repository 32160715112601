import { useSelector } from 'react-redux';
import { includes } from 'lodash-es';
import { AuthSelectors } from '../../store/general/auth/selectors';

export const useSpecialistsProfile = () => {
	const user = useSelector(AuthSelectors.user);

	if (user.content) {
		return {
			loaded: true,
			data: user.content,
			isActive: user.content.specialists,
			isSuperAdmin: includes(user.content.roles, 'SUPER_ADMIN'),
			isAdmin: includes(user.content.roles, 'ADMIN'),
			credentialProjectTypes: includes(user.content.roles, 'SUPER_ADMIN')
				? 3
				: user.content.credentialProjectTypes || 0,
		};
	} else {
		return {
			loaded: false,
			data: undefined,
			isActive: false,
			credentialProjectTypes: 0,
			isSuperAdmin: false,
			isAdmin: false,
		};
	}
};
