import { actionCreatorFactory } from 'typescript-fsa';
import {
	RemoveQuestionsRequest,
	RestoreQuestionsRequest,
	UpdateQuestionRequest,
	UpdateQuestionResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('QUESTIONS');

export const QuestionsActions = {
	update: ActionFactory.async<UpdateQuestionRequest, UpdateQuestionResponse, Error>('UPDATE'),
	batchRemove: ActionFactory.async<RemoveQuestionsRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreQuestionsRequest, {}, Error>('BATCH_RESTORE'),
};
