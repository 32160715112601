import { toast } from 'react-toastify';
import { isFunction } from 'lodash-es';
import i18n from 'i18next';
import { AsyncActionCreators } from 'typescript-fsa';
import { baseFetch, HttpMethods, interceptor, queryRaceInit, queryRaceSuccess } from '@ea/common';
import { IThunkAction, IThunkDispatch } from '../core/interfaces/store';
import { extractActionResult, extractActionError } from '../core/services/store';
import { specialistsConfig } from '../config/specialists';
import { workersConfig } from '../config/workers';
import { authorizeInterceptor } from './authorizeInterceptor';
import { IAppState } from './mainReducer';

export interface IFetchParams<P, R> {
	params: P | ((getState: () => IAppState) => P);
	url: string;
	method: HttpMethods;
	headers?: { [key: string]: string };
	actions: AsyncActionCreators<P, R, Error>;
	onSuccess?: (dispatch: IThunkDispatch, getState: () => IAppState, result?: R | null) => void;
	transformData?: (result: any, getState: () => IAppState) => R;
	onFail?: (dispatch: IThunkDispatch, error: { name: string; message: string }) => void;
	noResponse?: boolean;
	noSendParams?: (keyof P)[];
	noErrorToast?: boolean;
	noSendLanguage?: boolean;
	allowEmptyParams?: boolean;
	useQueryRace?: boolean;
	getErrorMessage?: (message: string) => string;
	mockData?: R;
	prefix?: string;
}

export const callApi = <P, R>({
	params: rawParams,
	url,
	method,
	headers,
	actions,
	noResponse = false,
	transformData,
	noSendParams,
	noErrorToast,
	useQueryRace = true,
	noSendLanguage = false,
	allowEmptyParams = true,
	getErrorMessage = (message) => message,
	onSuccess,
	onFail,
	mockData,
	prefix = '/api',
}: IFetchParams<P, R>): IThunkAction<R> => {
	return async (dispatch, getState, { history }): Promise<R> => {
		return new Promise((resolve, reject) => {
			const state = getState();
			const receivedParams = isFunction(rawParams) ? rawParams(getState) : rawParams;
			const { sendParams, params } = interceptor(receivedParams, noSendParams, noSendLanguage, allowEmptyParams);
			const key = useQueryRace ? queryRaceInit(actions) : '';

			dispatch(actions.started(sendParams));

			if (mockData) {
				setTimeout(() => {
					const transformedResult = transformData ? transformData(mockData, getState) : mockData;
					return resolve(
						extractActionResult(
							dispatch(actions.done({ params: sendParams, result: transformedResult as R })),
						),
					);
				}, 500);
			} else {
				return baseFetch<P, R>(
					`${prefix}/${url}`,
					params,
					method,
					{ Authorization: `Bearer ${state.general.auth.token}`, ...headers },
					noResponse,
				).then(({ result, status, message }) => {
					if (queryRaceSuccess(actions, key)) {
						if (status >= 400) {
							authorizeInterceptor(history, dispatch, status);

							const error = {
								name: status.toString(),
								message: ({ ...result } as Error).message || message || status.toString(),
							};

							if (status === 502) {
								history.push('/login');
								if (!noErrorToast) {
									toast.error(i18n.t('errors.serviceUnavailable'), {
										toastId: '502',
									});
								}
							} else if (history.location.pathname !== '/404') {
								if (error.message === 'PAGE_NOT_FOUND') {
									history.push('/404');
								} else {
									if (!noErrorToast && status !== 403) {
										toast.error(getErrorMessage(error.message));
									}
								}
							}

							if (onFail) {
								onFail(dispatch, error);
							}
							reject(extractActionError(dispatch(actions.failed({ params: sendParams, error }))));
						} else {
							const transformedResult = transformData ? transformData(result, getState) : result;
							if (onSuccess) {
								onSuccess(dispatch, getState, transformedResult as R);
							}
							resolve(
								extractActionResult(
									dispatch(actions.done({ params: sendParams, result: transformedResult as R })),
								),
							);
						}
					} else {
						// в случае query race error, что бы не было ошибок в консоли, завершим промис удачно но без ответа
						resolve();
					}
				});
			}
		});
	};
};

export const specialistsApi = <P, R>(args: IFetchParams<P, R>) => {
	return callApi({
		...args,
		prefix: specialistsConfig.backendPrefix,
	});
};

export const workersApi = <P, R>(args: IFetchParams<P, R>) => {
	return callApi({
		...args,
		prefix: workersConfig.backendPrefix,
	});
};
