import React, { FC } from 'react';
import styled from 'astroturf';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from '../../i18n/hooks';
import { routes } from '../../core/routes/paths';
import { useWorkersProfile } from '../../hooks/workers/useWorkersProfile';

export const Sites: FC = () => {
	const { t } = useTranslation();

	const workersProfile = useWorkersProfile();

	const disabledWorkers = !workersProfile.data?.workers;

	return (
		<Content>
			<Container>
				<Column>
					<Row>
						<Block to={routes.specialists.library.root.link()} type={'specialist'}>
							<BlockTitle>{t('words.specialistsSection')}</BlockTitle>
							<BlockIcon src={require('../../static/images/sections/specialists.png')} />
						</Block>
						<Block
							disabled={disabledWorkers}
							to={
								disabledWorkers
									? '#'
									: workersProfile.isForeman
									? routes.foreman.root.link()
									: routes.workers.root.link()
							}
							type={'worker'}
						>
							{disabledWorkers && (
								<Tooltip
									placement={'top-end'}
									title={
										'Модуль недоступен для вашей роли. Обратитесь к администратору на вашем предприятии'
									}
								>
									<Lock src={require('../../static/images/sections/lock.png')} />
								</Tooltip>
							)}
							<BlockTitle>{t('words.workersSection')}</BlockTitle>
							<BlockIcon src={require('../../static/images/sections/workers.png')} />
						</Block>
					</Row>

					<PersonalAssessmentBlock to={routes.specialists.estimate.root.link()} type={'assessment'}>
						<Stars>
							<StarIcon src={require('../../static/images/sections/star-gold.png')} />
							<StarIcon src={require('../../static/images/sections/star-gold.png')} />
							<StarIcon src={require('../../static/images/sections/star-gold.png')} />
							<StarIcon src={require('../../static/images/sections/star-gold.png')} />
							<StarIcon src={require('../../static/images/sections/star-gray.png')} />
						</Stars>
						<BlockTitle>{t('words.personalAssessment')}</BlockTitle>
					</PersonalAssessmentBlock>
				</Column>
			</Container>
		</Content>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1129px;
	min-height: calc(100vh - 124px);
	padding: 40px 0 60px;
	margin: 0 auto;
	max-height: 735px;
`;

const Block = styled(Link)<{ type?: string; disabled?: boolean }>`
	position: relative;
	margin: 0 12px;
	background: #f5f5f5;
	width: 515px;
	height: 390px;
	padding-top: 105px;
	padding-bottom: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	border-bottom: solid 8px transparent;
	color: inherit;

	&.type-specialist {
		border-color: #54c0eb;
	}

	&.type-worker {
		border-color: #40596b;
	}

	&.type-assessment {
		border-color: #f5a522;
	}
	&.disabled {
		opacity: 50%;
		cursor: default;
	}
`;

const PersonalAssessmentBlock = styled(Block)`
	width: auto;
	height: 50px;
	flex-direction: row;
	margin-top: 24px;
	justify-content: center;
`;
const BlockTitle = styled.div`
	font-size: 24px;
	font-weight: bold;
	line-height: 32px;
`;
const BlockIcon = styled.img`
	width: 124px;
`;

const StarIcon = styled.img`
	width: 27px;
	& + & {
		margin-left: 6px;
	}
`;

const Lock = styled.img`
	position: absolute;
	right: 24px;
	top: 24px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const Stars = styled.div`
	display: flex;
	flex-direction: row;
	margin-right: 19px;
`;
const Content = styled.div``;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
