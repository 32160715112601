import { actionCreatorFactory } from 'typescript-fsa';
import { UpdatePersonTeamSkillsRequest, UpdatePersonTeamSkillsResponse } from './typing';

const ActionFactory = actionCreatorFactory('WORKERS/COMMON/PERSON_TEAM_SKILLS');

export const PersonTeamSkillsActions = {
	update: ActionFactory.async<UpdatePersonTeamSkillsRequest, UpdatePersonTeamSkillsResponse, Error>(
		'UPDATE',
	),
};
