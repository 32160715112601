import { actionCreatorFactory } from 'typescript-fsa';
import { RememberParams, RememberScrollPosition } from '../../../../../service/typings/actions/grid';
import {
	GetFilterOptionsRequest,
	GetFilterOptionsResponse,
	GetFiltersRequest,
	GetFiltersResponse,
	GetFilterValuesRequest,
	GetFilterValuesResponse,
	UpdateFiltersRequest,
	UpdateFiltersResponse,
} from '../../../../../service/list/filters/api';
import {
	GetStatisticsByGroupsRequest,
	GetStatisticsByGroupsResponse,
} from '../../../../../service/list/selection/groups/api';
import { SelectionByGroups } from '../../../../../service/list/selection/groups/typings';
import {
	GetSkillsListRequest,
	GetSkillsListResponse,
	RemoveSkillsListRequest,
	RestoreSkillsListRequest,
} from './typings';

const ActionFactory = actionCreatorFactory('WORKERS_FOREMAN_SKILLS_LIST');

export const ForemanSkillsListActions = {
	getList: ActionFactory.async<GetSkillsListRequest, GetSkillsListResponse, Error>('GET_LIST'),
	getSearch: ActionFactory.async<GetSkillsListRequest, GetSkillsListResponse, Error>('GET_SEARCH'),
	loadMore: ActionFactory.async<GetSkillsListRequest, GetSkillsListResponse, Error>('LOAD_MORE'),
	rememberParams: ActionFactory<RememberParams>('REMEMBER_PARAMS'),
	resetParams: ActionFactory('RESET_PARAMS'),
	// Selection
	updateSelection: ActionFactory<SelectionByGroups>('UPDATE_SELECTION'),
	getStatistics: ActionFactory.async<GetStatisticsByGroupsRequest, GetStatisticsByGroupsResponse, Error>(
		'GET_STATISTICS',
	),
	// Filters
	getFilters: ActionFactory.async<GetFiltersRequest, GetFiltersResponse, Error>('GET_FILTERS'),
	getFilterValues: ActionFactory.async<GetFilterValuesRequest, GetFilterValuesResponse, Error>('GET_FILTER_VALUES'),
	getFilterOptions: ActionFactory.async<GetFilterOptionsRequest, GetFilterOptionsResponse, Error>(
		'GET_FILTER_OPTIONS',
	),
	updateFilters: ActionFactory.async<UpdateFiltersRequest, UpdateFiltersResponse, Error>('UPDATE_FILTERS'),
	// Scroll
	rememberScroll: ActionFactory<RememberScrollPosition>('REMEMBER_SCROLL'),
	// Remove
	batchRemove: ActionFactory.async<RemoveSkillsListRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreSkillsListRequest, {}, Error>('BATCH_RESTORE'),
};
