import { actionCreatorFactory } from 'typescript-fsa';
import { RememberParams, RememberScrollPosition } from '../../../../../service/typings/actions/grid';
import {
	GetFilterOptionsRequest,
	GetFilterOptionsResponse,
	GetFiltersRequest,
	GetFiltersResponse,
	GetFilterValuesRequest,
	GetFilterValuesResponse,
	UpdateFiltersRequest,
	UpdateFiltersResponse,
} from '../../../../../service/list/filters/api';
import {
	GetStatisticsByGroupsRequest,
	GetStatisticsByGroupsResponse,
} from '../../../../../service/list/selection/groups/api';
import { SelectionByGroups } from '../../../../../service/list/selection/groups/typings';
import {
	GetSkillPersonsRequest,
	GetSkillPersonsResponse,
	PersonsRequest,
	RemoveSkillPersonsRequest,
	RestoreSkillPersonsRequest,
	AssignPersonsCheckupRequest,
	AssignPersonsCheckupResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('WORKERS/FOREMAN_SKILL_PERSONS');

export const SkillPersonsActions = {
	getList: ActionFactory.async<PersonsRequest<GetSkillPersonsRequest>, GetSkillPersonsResponse, Error>('GET_LIST'),
	loadMore: ActionFactory.async<PersonsRequest<GetSkillPersonsRequest>, GetSkillPersonsResponse, Error>('LOAD_MORE'),
	// Params:
	rememberParams: ActionFactory<RememberParams>('REMEMBER_PARAMS'),
	resetParams: ActionFactory('RESET_PARAMS'),
	// Selection
	updateSelection: ActionFactory<SelectionByGroups>('UPDATE_SELECTION'),
	getStatistics: ActionFactory.async<
		PersonsRequest<GetStatisticsByGroupsRequest>,
		GetStatisticsByGroupsResponse,
		Error
	>('GET_STATISTICS'),
	assignCheckup: ActionFactory.async<AssignPersonsCheckupRequest, AssignPersonsCheckupResponse, Error>(
		'ASSIGN_CHECKUP',
	),
	// Filters
	getFilters: ActionFactory.async<PersonsRequest<GetFiltersRequest>, GetFiltersResponse, Error>('GET_FILTERS'),
	getFilterValues: ActionFactory.async<PersonsRequest<GetFilterValuesRequest>, GetFilterValuesResponse, Error>(
		'GET_FILTER_VALUES',
	),
	getFilterOptions: ActionFactory.async<PersonsRequest<GetFilterOptionsRequest>, GetFilterOptionsResponse, Error>(
		'GET_FILTER_OPTIONS',
	),
	updateFilters: ActionFactory.async<PersonsRequest<UpdateFiltersRequest>, UpdateFiltersResponse, Error>(
		'UPDATE_FILTERS',
	),
	// Scroll
	rememberScroll: ActionFactory<RememberScrollPosition>('REMEMBER_SCROLL'),
	// Remove
	batchRemove: ActionFactory.async<RemoveSkillPersonsRequest, {}, Error>('BATCH_REMOVE'),
	batchRestore: ActionFactory.async<RestoreSkillPersonsRequest, {}, Error>('BATCH_RESTORE'),
};
