import React, { FC, useLayoutEffect, useState } from 'react';
import styled from 'astroturf';
import { useKeyPress } from '@ea/admin/src/hooks/general/useKeyPress';
import { IconButton } from '../elements/buttons/IconButton';
import { ClassificationLevel } from './ClassificationLevel';
import { EditableEntity } from './EditableEntity';

interface Props {
	level: number;
	maxLevel?: number;
	editing?: boolean;
	editable?: boolean;
	className?: string;
	onEdit?: () => void;
	onSave?: (value: number) => unknown;
	onCancel?: () => void;
}

export const EditableClassificationLevel: FC<Props> = ({
	editable,
	level,
	className,
	maxLevel = 4,
	editing = true,
	onCancel,
	onEdit,
	onSave,
}) => {
	const [internalValue, setInternalValue] = useState(level);

	const onChange = (value: number) => {
		setInternalValue(value);
	};

	const onSaveInternal = () => {
		if (onSave && internalValue !== level) {
			Promise.resolve(onSave(internalValue)).then(() => onCancel && onCancel());
		} else {
			onCancel && onCancel();
		}
	};

	useLayoutEffect(() => {
		setInternalValue(level);
	}, [level, editing]);

	return (
		<EditableEntity
			className={className}
			editable={editable}
			editing={editing}
			onEdit={onEdit}
			onSave={onSaveInternal}
			onCancel={onCancel}
		>
			<StyledClassificationLevel
				editing={editing}
				level={editing ? internalValue : level}
				maxLevel={maxLevel}
				onChange={onChange}
			/>
		</EditableEntity>
	);
};

const StyledClassificationLevel = styled(ClassificationLevel)`
	width: 100%;
`;
