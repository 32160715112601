import React, { FC, forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import styled from 'astroturf';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { includes } from 'lodash-es';
import { cellClasses, GeneralTable, GeneralTableInterface, SelectRenderer, GridColumn, GridRow } from '@ea/table';
import { AdvancedSelect, Dict } from '@ea/common';
import { useTranslation } from '../../../../../../../i18n/hooks';
import { AccessListSelector } from '../../../../../../../store/specialists/lists/access/selectors';
import { IThunkDispatch } from '../../../../../../../core/interfaces/store';
import { AccessListActions } from '../../../../../../../store/specialists/lists/access/actions';
import { useDynamicFunction } from '../../../../../../../hooks/general/useDynamicFunction';
import { useChangeDetection } from '../../../../../../../hooks/general/useChangeDetection';
import { accessTypes, questionAccessTypes } from '../../../../../../../core/services/access';
import { AccessRowType } from '../../../../../../../store/specialists/lists/access/typings';
import { FiltersValuesState } from '../../../../../../../service/typings/filters';
import { useAccessTableSelection } from './useAccessTableSelection';

export type AccessEntity = 'competences' | 'questions' | 'profiles' | 'persons' | 'projectTypes';

type TAccessHeaders = {
	[key in AccessEntity]: string[];
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const accessFields: Record<AccessEntity, string[]> = {
	profiles: ['sp1Id', 'sp2Id', 'sp3Id', 'sp4Id', 'sp5Id'],
	persons: ['sp1Id', 'sp2Id', 'sp3Id', 'sp4Id', 'sp5Id'],
	competences: ['functionId', 'competenceId'],
	questions: ['functionId', 'competenceId'],
};

export const accessHeaders: TAccessHeaders = {
	competences: ['competenceName', 'credentials'],
	questions: ['competenceName', 'competenceCredentials', 'questionAccess'],
	profiles: ['sp1Name', 'sp2Name', 'sp3Name', 'sp4Name', 'sp5Name', 'credentials'],
	persons: ['sp1Name', 'sp2Name', 'sp3Name', 'sp4Name', 'sp5Name', 'credentials'],
	projectTypes: ['sp1Name', 'sp2Name', 'sp3Name', 'sp4Name', 'sp5Name', 'credentials'],
};

interface Props {
	entity: AccessEntity;
	email: string;
	editing?: boolean;
	selectable?: boolean;
	filters?: FiltersValuesState;
	onLoadMore?: () => void;
	mode?: 'unknown' | 'create' | 'edit';
}

export interface IAccessTable {
	redrawRows: () => void;
}

export const ACCESS_LIST_PAGE_SIZE = 50;

export const AccessTable = forwardRef<IAccessTable, Props>(
	({ filters, selectable, entity, email, editing, onLoadMore, mode }, ref) => {
		const gridRef = useRef<GeneralTableInterface>(null);

		const { t } = useTranslation();
		const dispatch = useDispatch<IThunkDispatch>();

		// TODO: возможно имеет смысл вынести их в пропсы
		const allRows = useSelector(AccessListSelector.rows, isEqual);
		const data = useSelector(AccessListSelector.data, isEqual);
		const changes = useSelector(AccessListSelector.changeRows, isEqual);
		const loadedData = useSelector(AccessListSelector.loaded);

		// TODO: временное решение, пока грузятся все данные
		const rows = useMemo(() => {
			if (filters && (filters.companyId || filters.functionId)) {
				const allowedCompanies: number[] = [];
				const allowedFunctions: number[] = [];

				allRows.forEach((row: GridRow) => {
					const functionId = row.functionId || row.id;
					const companyId = row.companyId;
					if (
						functionId &&
						companyId &&
						(!filters.companyId || filters.companyId.find((el) => el.id === companyId)) &&
						(!filters.functionId || filters.functionId.find((el) => el.id === functionId))
					) {
						allowedCompanies.push(companyId);
						allowedFunctions.push(functionId);
					}
				});

				return allRows.filter((row: GridRow) => {
					if (row.header) {
						return includes(allowedCompanies, row.id);
					} else if (row.section) {
						return includes(allowedFunctions, row.id);
					} else {
						return includes(allowedCompanies, row.companyId) && includes(allowedFunctions, row.functionId);
					}
				});
			} else {
				return allRows;
			}
		}, [allRows, filters]);

		const onRowSelected = useAccessTableSelection(email, entity, mode);

		const handleChangeRow = useCallback(
			(row: any, type: AccessRowType) => (item: any) => {
				dispatch(
					AccessListActions.rowChange({
						...row,
						useSelection: selectable,
						credentials: item.key,
						type,
					}),
				);
			},
			[dispatch, selectable],
		);

		const getParentFunctionCredentials = useDynamicFunction((id: number) => {
			const accessData = data.content.access[id];
			if (accessData) {
				if (changes[accessData.functionId]) {
					return changes[accessData.functionId].credentials;
				} else {
					return data.content.functions[accessData.functionId]?.credentials;
				}
			}
		});

		const columnOptions = useMemo<Dict<GridColumn>>(
			() => ({
				number: {
					width: 61,
					pinned: 'left',
					lockPosition: true,
					cellClass: cellClasses.first,
					resizeable: false,
					headerName: '#',
				},
				access: {
					headerName: t('words.access'),
					suppressSizeToFit: false,
					sortable: false,
					alterable: false,
					suppressMovable: true,
				},

				competenceName: {
					headerName: t('words.competence'),
					sortable: false,
					suppressSizeToFit: false,
					alterable: false,
					suppressMovable: true,
				},

				sp1Name: {
					headerName: t('words.sp1'),
					sortable: false,
					suppressSizeToFit: false,
					alterable: false,
					suppressMovable: true,
				},
				sp2Name: {
					headerName: t('words.sp2'),
					sortable: false,
					suppressSizeToFit: false,
					alterable: false,
					suppressMovable: true,
				},
				sp3Name: {
					headerName: t('words.sp3'),
					sortable: false,
					suppressSizeToFit: false,
					alterable: false,
					suppressMovable: true,
				},
				sp4Name: {
					headerName: t('words.sp4'),
					sortable: false,
					suppressSizeToFit: false,
					alterable: false,
					suppressMovable: true,
				},
				sp5Name: {
					headerName: t('words.sp5'),
					sortable: false,
					suppressSizeToFit: false,
					alterable: false,
					suppressMovable: true,
				},

				competenceCredentials: {
					headerName: t('words.credentialsCompetences'),
					sortable: false,
					width: 200,
					alterable: false,
					resizable: false,
					suppressMovable: true,
					valueGetter: ({ data }: ValueGetterParams) => {
						const value = accessTypes.find((item) => item.key === data.competenceCredentials);
						return value && value.name;
					},
				},

				credentials: {
					headerName: t('words.credentials'),
					width: 200,
					sortable: false,
					alterable: false,
					suppressSizeToFit: true,
					cellRenderer: editing ? SelectRenderer : undefined,
					suppressMovable: true,
					valueGetter: ({ data }: ValueGetterParams) => {
						let value;
						if (entity === 'questions') {
							value = questionAccessTypes.find((item) => item.key === data.credentials);
						} else {
							value = accessTypes.find((item) =>
								editing && data.id < 0 && !data.changed
									? item.key === -1
									: item.key === data.credentials,
							);
						}
						return editing ? value : value && value.name;
					},
					cellRendererParams({ data }: ICellRendererParams) {
						return {
							onGetItems: () => {
								const functionCredentials = getParentFunctionCredentials(data.id);
								return (entity === 'questions' ? questionAccessTypes : accessTypes).filter((el) => {
									const valid = el.key <= data.credentialsMax;
									return functionCredentials !== undefined &&
										functionCredentials > data.credentialsMax
										? valid && el.key !== -1
										: valid;
								});
							},
							disabled: selectable && !data.selected,
							onChange: handleChangeRow(data, 'access'),
						};
					},
				},

				questionAccess: {
					headerName: t('words.questions'),
					width: 200,
					sortable: false,
					alterable: false,
					suppressSizeToFit: true,
					cellRenderer: editing ? SelectRenderer : undefined,
					suppressMovable: true,
					valueGetter: ({ data }: ValueGetterParams) => {
						const value = questionAccessTypes.find((item) => item.key === data.credentials);
						return editing ? value : value && value.name;
					},
					cellRendererParams({ data }: ICellRendererParams) {
						return {
							items: questionAccessTypes.filter((el) =>
								data.credentialsMax === null ? el.key <= 0 : el.key <= data.credentialsMax,
							),
							disabled: selectable && !data.selected,
							onChange: handleChangeRow(data, 'access'),
						};
					},
				},
			}),
			[t, editing, selectable, handleChangeRow, entity],
		);

		const columns = useMemo(() => {
			return accessHeaders[entity].map((field, index) => ({
				field,
				...columnOptions[field],
				suppressResize: index >= accessHeaders[entity].length - 2,
			}));
		}, [entity, columnOptions]);

		useChangeDetection(() => {
			if (gridRef.current) {
				gridRef.current.redrawRows();
			}
		}, [editing]);

		useImperativeHandle(ref, () => ({
			redrawRows: () => {
				if (gridRef.current) {
					gridRef.current.redrawRows();
				}
			},
		}));

		const loading = !loadedData;

		const listForRightContent = useCallback(
			(row: GridRow, type: 'function' | 'company') => [
				...(entity === 'questions' ? questionAccessTypes : accessTypes).filter(
					(el) =>
						(row.data.credentialsMax === null ? el.key <= 0 : el.key <= row.data.credentialsMax) &&
						el.key !== -1,
				),
				...(type === 'function'
					? [
							{
								key: -1,
								name: 'Как у компании',
							},
					  ]
					: []),
			],
			[entity],
		);

		const rightContentRenderer = useDynamicFunction((row: GridRow) => {
			const type = row.data.section ? 'function' : 'company';
			return (
				<AccessSelectWrapper>
					{editing ? (
						<StyledSelect
							value={listForRightContent(row, type).find((item) =>
								editing && row.data.id < 0 ? item.key === -1 : item.key === row.data.credentials,
							)}
							items={listForRightContent(row, type)}
							onChange={handleChangeRow(row.data, type)}
						/>
					) : null}
				</AccessSelectWrapper>
			);
		});

		return (
			<StyledGeneralTable
				key={`accessList`}
				ref={gridRef}
				rows={rows}
				loading={loading}
				pageSize={ACCESS_LIST_PAGE_SIZE}
				onLoadMore={onLoadMore}
				columns={columns}
				onRowSelected={onRowSelected}
				selectable={selectable}
				colResizeDefault={'shift'}
				fullWidthParams={{
					rightContentRenderer,
				}}
				autoFit={true}
			/>
		);
	},
);

const StyledGeneralTable = styled(GeneralTable)`
	height: 60vh;
`;

const StyledSelect = styled(AdvancedSelect)`
	height: 24px;
	padding: 5px 12px;
`;
const AccessSelectWrapper = styled.div`
	margin-left: auto;
	width: 200px;
	padding: 0 2px;
`;
