import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import {
	AccessDetail,
	SecondSkillDetail,
	SkillDetail,
	SkillPosition,
	SkillTeam,
} from '../../../../../service/typings/workers/common/entities';
import { Row } from '../../../../../service/typings/lists';
import { PersonTeamSkillsActions } from '../../entities/personTeamSkills/actions';
import { AdminSkillsListActions } from '../../../admin/lists/skills/actions';
import { ForemanSkillsListActions } from '../../../foreman/lists/skills/actions';
import { SkillDetailsActions } from './actions';

type Data = (SkillDetail | SecondSkillDetail | AccessDetail) & { changed?: boolean };

export interface SkillDetailState {
	data: ContentLoading<Data | undefined>;
	positions: ContentLoading<SkillPosition[]>;
	persons: ContentLoading<Row[]>;
	trainings: ContentLoading<Row[]>;
}

const initialState: SkillDetailState = {
	data: initialContentLoading(undefined),
	positions: initialContentLoading([]),
	persons: initialContentLoading([]),
	trainings: initialContentLoading([]),
};

export const SkillDetailReducer = reducerWithInitialState(initialState)
	.case(SkillDetailsActions.getSkill.started, (state, payload) => ({
		...state,
		data: {
			...state.data,
			loading: {
				status: 'loading',
				type: payload.refresh ? 'refresh' : 'full',
			},
		},
		positions: payload.refresh ? state.positions : initialContentLoading([]),
		trainings: payload.refresh ? state.trainings : initialContentLoading([]),
		persons: payload.refresh ? state.persons : initialContentLoading([]),
	}))
	.case(SkillDetailsActions.getSkill.done, (state, { result }) => {
		return {
			...state,
			data: {
				...state.data,
				content: result,
				loading: {
					status: 'loaded',
				},
			},
		};
	})
	.case(SkillDetailsActions.getSkill.failed, (state) => {
		return {
			...state,
			data: {
				...state.data,
				content: undefined,
				loading: {
					status: 'loaded',
				},
			},
		};
	})
	.case(SkillDetailsActions.getTrainings.started, (state) => ({
		...state,
		trainings: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(SkillDetailsActions.getTrainings.done, (state, { result, params }) => ({
		...state,
		trainings: {
			content: result,
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(SkillDetailsActions.getTrainings.failed, (state, { params, error }) => ({
		...state,
		trainings: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))
	.case(SkillDetailsActions.getPersons.started, (state) => ({
		...state,
		persons: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(SkillDetailsActions.getPersons.done, (state, { result, params }) => ({
		...state,
		persons: {
			content: normalizePersons(result),
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(SkillDetailsActions.getPersons.failed, (state, { params, error }) => ({
		...state,
		persons: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))
	.case(SkillDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				...result,
			},
		},
	}))
	.case(PersonTeamSkillsActions.update.done, (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: state.data.content && {
				...state.data.content,
				changed: true,
			},
		},
		persons: {
			...state.data,
			content: state.persons.content?.map((person) =>
				person.personTeamSkill === params.id
					? {
							...person,
							...params,
					  }
					: person,
			),
		},
	}))
	.cases(
		[
			AdminSkillsListActions.batchRemove.done,
			ForemanSkillsListActions.batchRemove.done,
			AdminSkillsListActions.batchRestore.done,
			ForemanSkillsListActions.batchRestore.done,
		],
		(state) => ({
			...state,
			data: {
				...state.data,
				content: state.data.content && {
					...state.data.content,
					changed: true,
				},
			},
		}),
	);

function normalizePersons(data: SkillTeam[]) {
	return data.reduce<Row[]>(
		(result, { persons, ...info }) => [
			...result,
			{
				section: true,
				...info,
			},
			...persons,
		],
		[],
	);
}
