import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkerProfile } from '../../service/typings/workers/profile';
import { ProfileCard } from './ProfileCard';

interface Props {
	data: WorkerProfile;
	className?: string;
	id?: number;
}

export const WorkerInfo: FC<Props> = ({ className, data, id }) => {
	const { t } = useTranslation();

	const isWorkerProfileCard = true;

	const fields: { label: string; value: string | number | null }[] = useMemo(
		() => [
			{
				label: t('common.words.position'),
				value: data.position ? data.position.name : null,
			},
			{
				label: t('common.words.rank'),
				value: data.grade,
			},
		],
		[],
	);

	return (
		<ProfileCard
			id={id}
			fullName={data.fio}
			score={data.score}
			className={className}
			fields={fields}
			isWorkerProfileCard={isWorkerProfileCard}
		/>
	);
};
