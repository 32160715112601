import { actionCreatorFactory } from 'typescript-fsa';
import {
	CreateSkillRequest,
	CreateSkillResponse,
	GetSkillPersonsRequest,
	GetSkillPersonsResponse,
	GetSkillPositionsRequest,
	GetSkillPositionsResponse,
	GetSkillRequest,
	GetSkillResponse,
	GetSkillTrainingsRequest,
	GetSkillTrainingsResponse,
	UpdateSkillRequest,
	UpdateSkillResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('FOREMAN_SKILL_DETAILS');

export const SkillDetailsActions = {
	getSkill: ActionFactory.async<GetSkillRequest, GetSkillResponse, Error>('GET_SKILL'),
	create: ActionFactory.async<CreateSkillRequest, CreateSkillResponse, Error>('CREATE_SKILL'),
	update: ActionFactory.async<UpdateSkillRequest, UpdateSkillResponse, Error>('UPDATE_SKILL'),
	getPositions: ActionFactory.async<GetSkillPositionsRequest, GetSkillPositionsResponse, Error>('GET_POSITIONS'),
	getTrainings: ActionFactory.async<GetSkillTrainingsRequest, GetSkillTrainingsResponse, Error>('GET_TRAININGS'),
	loadMoreTrainings: ActionFactory.async<GetSkillTrainingsRequest, GetSkillTrainingsResponse, Error>(
		'LOAD_MORE_TRAININGS',
	),
	getPersons: ActionFactory.async<GetSkillPersonsRequest, GetSkillPersonsResponse, Error>('GET_PERSONS'),
	loadMorePersons: ActionFactory.async<GetSkillPersonsRequest, GetSkillPersonsResponse, Error>('LOAD_MORE_PERSONS'),
};
