import React, { Suspense, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Settings } from 'luxon';
import { create } from 'jss';
import './i18n';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';

import { isMac, isSafari } from '@ea/common';
import { configureStore } from './store/configureStore';
import { EmptyContent } from './components/general/EmptyContent';
import { Routes } from './pages/Routes';
import { AuthorizationWrapper } from './components/general/service/AuthorizationWrapper';

Settings.defaultLocale = 'ru';

export const history = createBrowserHistory();
const { store } = configureStore(history);

if (window && window.history) {
	if ('scrollRestoration' in window.history) {
		window.history.scrollRestoration = 'manual';
	}
}

const insertionPoint = document.getElementById('jss-insertion-point');
const jss = create({
	...jssPreset(),
	insertionPoint: insertionPoint || undefined,
});

function App() {
	useEffect(() => {
		if (isSafari() || isMac()) {
			document.body.classList.add('mac-browser');
		}
	}, []);

	return (
		<StylesProvider jss={jss}>
			<Router history={history}>
				<Provider store={store}>
					<Suspense fallback={<EmptyContent />}>
						<AuthorizationWrapper>
							<Routes />
						</AuthorizationWrapper>
						<ToastContainer autoClose={4000} className="toaster" />
					</Suspense>
				</Provider>
			</Router>
		</StylesProvider>
	);
}

export default App;
