import React, { FC } from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { AuthSelectors } from '../../../store/general/auth/selectors';
import { routes } from '../../../core/routes/paths';
import { useWorkersProfile } from '../../../hooks/workers/useWorkersProfile';
import { useSpecialistsProfile } from '../../../hooks/specialists/useSpecialistsProfile';

export const MainRoute: FC = ({ children }) => {
	const workersProfile = useWorkersProfile();
	const specialistsProfile = useSpecialistsProfile();

	if (workersProfile.loaded) {
		if (workersProfile.isActive && specialistsProfile.isActive) {
			return <>{children}</>;
		} else {
			if (workersProfile.isActive) {
				if (workersProfile.isForeman) {
					return <Redirect to={routes.foreman.root.path} />;
				} else {
					return <Redirect to={routes.workers.root.path} />;
				}
			} else if (specialistsProfile.isActive) {
				return <Redirect to={routes.specialists.root.path} />;
			}
		}
	}
	return null;
};
