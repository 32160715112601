import { combineReducers } from 'redux';
import { WorkersAdminReducer } from './admin/reducer';
import { WorkersForemanReducer } from './foreman/reducer';
import { WorkersCommonReducer } from './common/reducer';

export const WorkersReducer = combineReducers({
	admin: WorkersAdminReducer,
	foreman: WorkersForemanReducer,
	common: WorkersCommonReducer,
});
