import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import { WorkerProfile } from '@ea/common';
import { PersonTeamSkillsActions } from '../../entities/personTeamSkills/actions';
import { PersonsListActions } from '../../../admin/lists/persons/actions';
import { PersonDetailsActions } from './actions';

export interface PersonDetailState {
	data: ContentLoading<WorkerProfile | undefined>;
}

const initialState: PersonDetailState = {
	data: initialContentLoading(undefined),
};

export const PersonDetailReducer = reducerWithInitialState(initialState)
	.case(PersonDetailsActions.get.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(PersonDetailsActions.get.done, (state, { result }) => {
		return {
			...state,
			data: {
				...state.data,
				content: result[0],
				loading: {
					status: 'loaded',
				},
			},
		};
	})
	.case(PersonDetailsActions.get.failed, (state) => {
		return {
			...state,
			data: {
				...state.data,
				content: undefined,
				loading: {
					status: 'loaded',
				},
			},
		};
	})
	.case(PersonDetailsActions.update.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: result[0],
		},
	}))
	.case(PersonTeamSkillsActions.update.done, (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: state.data.content && {
				...state.data.content,
				skill1: {
					...state.data.content.skill1,
					items: state.data.content.skill1.items.map((el) =>
						el.id === params.id ? { ...el, ...params } : el,
					),
				},
				skill2: {
					...state.data.content.skill2,
					items: state.data.content.skill1.items.map((el) =>
						el.id === params.id ? { ...el, ...params } : el,
					),
				},
				skill3: {
					...state.data.content.skill3,
					items: state.data.content.skill1.items.map((el) =>
						el.id === params.id ? { ...el, ...params } : el,
					),
				},
			},
		},
	}))
	.cases([PersonsListActions.batchRestore.done, PersonsListActions.batchRemove.done], (state) => ({
		...state,
		data: {
			...state.data,
			content: undefined,
		},
	}));
