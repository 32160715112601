import React, { FC } from 'react';
import styled from 'astroturf';
import { useKeyPress } from '@ea/admin/src/hooks/general/useKeyPress';
import { ReactComponent as ApplyIcon } from '../../images/icons/actions/apply.svg';
import { ReactComponent as EditIcon } from '../../images/icons/actions/edit.svg';
import { IconButton } from '../elements/buttons/IconButton';

interface Props {
	editable?: boolean;
	editing?: boolean;
	className?: string;
	onSave?: () => void;
	onCancel?: () => void;
	onEdit?: () => void;
}

export const EditableEntity: FC<Props> = ({ children, className, editing, editable, onSave, onCancel, onEdit }) => {
	useKeyPress('Escape', onCancel);

	return (
		<Wrapper className={className}>
			{children}
			{editable && (
				<StyledIconButton onClick={editing ? onSave : onEdit}>
					<IconWrapper>{editing ? <ApplyIcon /> : <EditIcon />}</IconWrapper>
				</StyledIconButton>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;

const StyledIconButton = styled(IconButton)`
	margin-left: 13px;
	margin-right: -8px;
`;

const IconWrapper = styled.div`
	width: 14px;
	height: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
