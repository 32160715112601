import { workersConfig } from '../../config/workers';
import { SkillType, SkillTypeCode } from '../../service/typings/workers/common/entities';
import { routes } from './paths';

const prefix = workersConfig.routePrefix;

export const workersRoutes = {
	root: { path: `${prefix}/`, link: () => `${prefix}` },
	library: {
		list: { path: `${prefix}/:companyId/library/`, link: (companyId: number) => `${prefix}/${companyId}/library` },
		trash: {
			path: `${prefix}/:companyId/library/trash`,
			link: (companyId: number) => `${prefix}/${companyId}/library/trash`,
		},
		create: {
			path: `${prefix}/:companyId/library/create`,
			link: (companyId: number) => `${prefix}/${companyId}/library/create`,
		},
	},
	firstSkill: {
		root: {
			path: `${prefix}/:companyId/library/skill/:skillId`,
			link: (companyId: number, id: number) => `${prefix}/${companyId}/library/skill/${id}`,
		},
	},
	secondSkill: {
		root: {
			path: `${prefix}/:companyId/library/secondary-skill/:skillId`,
			link: (companyId: number, id: number) => `${prefix}/${companyId}/library/secondary-skill/${id}`,
		},
	},
	access: {
		root: {
			path: `${prefix}/:companyId/library/access/:skillId`,
			link: (companyId: number, id: number) => `${prefix}/${companyId}/library/access/${id}`,
		},
	},
	persons: {
		card: {
			path: `${prefix}/:companyId/persons/:personId`,
			link: (companyId: number, personId: number) => `${prefix}/${companyId}/persons/${personId}`,
		},
		list: { path: `${prefix}/:companyId/persons/`, link: (companyId: number) => `${prefix}/${companyId}/persons/` },
		trash: {
			path: `${prefix}/:companyId/persons/trash`,
			link: (companyId: number) => `${prefix}/${companyId}/persons/trash`,
		},
	},
	trainings: {
		card: {
			path: `${prefix}/:companyId/trainings/:id`,
			link: (companyId: number, id: number) => `${prefix}/${companyId}/trainings/${id}`,
		},
		create: {
			path: `${prefix}/:companyId/trainings/create`,
			link: (companyId: number) => `${prefix}/${companyId}/trainings/create`,
		},
		list: {
			path: `${prefix}/:companyId/trainings`,
			link: (companyId: number) => `${prefix}/${companyId}/trainings`,
		},
		trash: {
			path: `${prefix}/:companyId/trainings/trash`,
			link: (companyId: number) => `${prefix}/${companyId}/trainings/trash`,
		},
	},
};

export function getSkillRoute(type: SkillTypeCode) {
	if (type === SkillTypeCode.Main) {
		return workersRoutes.firstSkill;
	} else if (type === SkillTypeCode.Second) {
		return workersRoutes.secondSkill;
	} else {
		return workersRoutes.access;
	}
}

export function getAdminSkillLink(companyId: number, skillId: number, type: number) {
	if (skillId) {
		if (type === SkillType.Main) {
			return routes.workers.firstSkill.root.link(companyId, skillId);
		} else if (type === SkillType.Second) {
			return routes.workers.secondSkill.root.link(companyId, skillId);
		} else if (type === SkillType.Access) {
			return routes.workers.access.root.link(companyId, skillId);
		}
	}
}
