import React, { FC, useCallback } from 'react';
import { Hue } from 'react-color/lib/components/common';
import ColorWrap, { CustomPickerInjectedProps } from 'react-color/lib/components/common/ColorWrap';
import styled from 'astroturf';
import { HSLColor, HSVColor } from 'react-color';
import { LimitedSaturation } from './LimitedSaturation';

interface Props extends CustomPickerInjectedProps {
	hsl?: HSLColor;
	hsv?: HSVColor;
	colors: string[];
	from: number;
	to: number;
}

const Picker: FC<Props> = ({ onChange, hsl, hsv, colors, from, to }) => {
	const onSelect = useCallback(
		(hex: string) => {
			onChange({
				hex,
				source: 'hex',
			} as any); // TODO: поправить типизацию
		},
		[onChange],
	);

	return (
		<>
			<SaturationWrapper>
				<LimitedSaturation hsl={hsl} hsv={hsv} onChange={onChange} from={from} to={to} />
			</SaturationWrapper>
			<HueWrapper>
				<Hue hsl={hsl} onChange={onChange} />
			</HueWrapper>
			<SwatchWrapper>
				{colors.map((color: string) => (
					<Swatch style={{ backgroundColor: color }} key={color} onClick={() => onSelect(color)} />
				))}
			</SwatchWrapper>
		</>
	);
};

export const WrappedPicker = ColorWrap((props) => (
	<Picker
		{...props}
		from={0}
		to={1}
		colors={[
			'#FF6900',
			'#FCB900',
			'#7BDCB5',
			'#00D084',
			'#8ED1FC',
			'#0693E3',
			'#ABB8C3',
			'#EB144C',
			'#F78DA7',
			'#9900EF',
		]}
	/>
));

export const DarkWrappedPicker = ColorWrap((props) => (
	<Picker
		{...props}
		from={0}
		to={0.65}
		colors={[
			'#A64500',
			'#A67B00',
			'#5DA688',
			'#00A669',
			'#5D89A6',
			'#056AA6',
			'#919CA6',
			'#A60E36',
			'#A65F70',
			'#6A00A6',
		]}
	/>
));

export const LightWrappedPicker = ColorWrap((props) => (
	<Picker
		{...props}
		from={0.65}
		to={1}
		colors={[
			'#FFB47E',
			'#FFDB76',
			'#7BDCB5',
			'#58CCA4',
			'#8ED1FC',
			'#5BB3E5',
			'#ABB8C3',
			'#EC708F',
			'#F78DA7',
			'#C071EC',
		]}
	/>
));

const Swatch = styled.div`
	margin-bottom: 5px;
	height: 14px;
	width: 14px;
	border-radius: 2px;
	cursor: pointer;
`;
const SwatchWrapper = styled.div`
	height: 20px;
	margin-top: 10px;
	margin-bottom: -5px;
	justify-content: space-between;
	display: flex;
`;
const SaturationWrapper = styled.div`
	height: calc(100% - 60px);
	position: relative;
`;
const HueWrapper = styled.div`
	height: 20px;
	position: relative;
	margin-top: 10px;
`;
