import { actionCreatorFactory } from 'typescript-fsa';
import {
	GetSkillPersonsRequest,
	GetSkillPersonsResponse,
	GetSkillPositionsRequest,
	GetSkillPositionsResponse,
	GetSkillRequest,
	GetSkillResponse,
	GetSkillTrainingsRequest,
	GetSkillTrainingsResponse,
} from './typings';

const ActionFactory = actionCreatorFactory('WORKERS_ADMIN_SKILL_DETAILS');

export const SkillDetailsActions = {
	getSkill: ActionFactory.async<GetSkillRequest, GetSkillResponse, Error>('GET_SKILL'),
	getPersons: ActionFactory.async<GetSkillPersonsRequest, GetSkillPersonsResponse, Error>('GET_PERSONS'),
	loadMorePersons: ActionFactory.async<GetSkillPersonsRequest, GetSkillPersonsResponse, Error>('LOAD_MORE_PERSONS'),
	getTrainings: ActionFactory.async<GetSkillTrainingsRequest, GetSkillTrainingsResponse, Error>('GET_TRAININGS'),
	loadMoreTrainings: ActionFactory.async<GetSkillTrainingsRequest, GetSkillTrainingsResponse, Error>(
		'LOAD_MORE_TRAININGS',
	),
	getPositions: ActionFactory.async<GetSkillPositionsRequest, GetSkillPositionsResponse, Error>('GET_POSITIONS'),
};
