import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { isUndefined } from 'lodash-es';

interface IProps extends ICellRendererParams {
	value: number;
}

export class ScoreRenderer implements ICellRenderer {
	element!: HTMLElement;

	public init(params: IProps) {
		this.element = document.createElement('div');
		this.render(params);
	}

	public render(params: IProps) {
		const score = params.value;

		if (!isUndefined(score) && score !== null) {
			if (score >= 0.9) {
				this.element.setAttribute('class', 'ag-grid-score-cell ag-grid-score-cell--perfect');
			} else if (score >= 0.7 && score <= 0.89) {
				this.element.setAttribute('class', 'ag-grid-score-cell ag-grid-score-cell--good');
			} else if (score >= 0.5 && score <= 0.69) {
				this.element.setAttribute('class', 'ag-grid-score-cell ag-grid-score-cell--medium');
			} else {
				if (score >= 0 && score <= 0.49) {
					this.element.setAttribute('class', 'ag-grid-score-cell ag-grid-score-cell--bad');
				}
			}
			this.element.innerHTML = String(score);
		} else {
			this.element.setAttribute('class', 'ag-grid-score-cell');
			this.element.innerHTML = '';
		}
	}

	public getGui() {
		return this.element;
	}

	public refresh(params: IProps) {
		this.render(params);
		return true;
	}
}
