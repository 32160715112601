import { forEach } from 'lodash-es';

export function omitEmpty(obj: any) {
	const result: any = {};
	Object.keys(obj).forEach((key) => {
		if (obj[key] !== null && typeof obj[key] !== 'undefined') {
			result[key] = obj[key];
		}
	});
	return result;
}

export function countTruthy(collection: any) {
	let number = 0;
	forEach(collection, (el) => {
		if (el) {
			number += 1;
		}
	});
	return number;
}

export function clearArray<T>(obj: T[]) {
	return obj.filter((el) => el);
}

export function isNumeric(value: any): value is number | string {
	return !Number.isNaN(parseFloat(value)) && isFinite(value);
}
