import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';

import { includes } from 'lodash-es';
import { Dict } from '../../../../../core/types';
import { FilterDefinition } from '../../../../../service/list/filters/typings';
import { SelectionByGroups } from '../../../../../service/list/selection/groups/typings';
import { extendSelection, shrinkSelection } from '../../../../../service/list/selection/groups/utils';
import { NormalizedList } from '../../../../../service/list/general/typings';
import { filterRows, joinRows, normalize } from '../../../../../service/list/general/utils';
import { AdminSkillsListActions } from '../skills/actions';
import { PersonDetailsActions } from '../../../common/details/persons/actions';
import { PersonsActions } from '../../../common/entities/persons/actions';
import { TrainingDetailsActions } from '../../details/trainings/actions';
import { TrainingsListActions } from './actions';

interface Data extends NormalizedList<any> {
	changed: boolean;
}

export interface TrainingsListState {
	data: PaginatedParametrizedContentLoading<Data>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	selection: SelectionByGroups;
	lastParams: Dict;
}

const initialData = () => ({
	changed: false,
	groups: {},
	subgroups: {},
	items: {},
	rows: [],
});

const initialState = (): TrainingsListState => ({
	data: initialPaginatedContentLoading(initialData(), {}),
	filters: initialContentLoading([], {}),
	selection: {
		rows: {},
		groups: {},
	},
	lastParams: {},
});

export const TrainingsListReducer = reducerWithInitialState(initialState())
	.case(TrainingsListActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(TrainingsListActions.getList.done, (state, { result, params }) => ({
		...state,
		data: {
			content: joinRows(initialData(), normalize(result._embedded.groups || [])),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			pagination: {
				...result.page,
				more: result._embedded.groups.length > 0,
			},
			params,
		},
		scroll: 0,
	}))
	.case(TrainingsListActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
			params,
		},
	}))
	.case(TrainingsListActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(TrainingsListActions.loadMore.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: joinRows(state.data.content, normalize(result._embedded.groups || [])),
			loading: {
				type: 'append',
				status: 'loaded',
			},
			pagination: {
				...result.page,
				more: result._embedded.groups.length > 0,
			},
		},
	}))

	.case(TrainingsListActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(TrainingsListActions.getFilters.done, (state, { params, result }) => ({
		...state,
		filters: {
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(TrainingsListActions.getFilters.failed, (state, { params, error }) => ({
		...state,
		filters: {
			content: [],
			loading: {
				status: 'loaded',
			},
			error,
			params,
		},
	}))
	.case(TrainingsListActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}))
	.case(TrainingsListActions.updateSelection, (state, data) => ({
		...state,
		selection: {
			...state.selection,
			...data,
		},
	}))
	.case(TrainingsListActions.getStatistics.done, (state, { result }) => ({
		...state,
		selection: extendSelection(state.selection, result),
	}))
	.cases([TrainingsListActions.batchRemove.done, TrainingsListActions.batchRestore.done], (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				rows: filterRows(state.data.content.rows, (row) => includes(params, row.id)),
			},
		},
		selection: shrinkSelection(state.selection, params),
	}))
	.cases([TrainingDetailsActions.updateTraining.done, TrainingDetailsActions.createTraining.done], (state) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				changed: true,
			},
		},
	}));
