import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	initialContentLoading,
	initialPaginatedContentLoading,
	PaginatedParametrizedContentLoading,
	ParametrizedContentLoading,
} from '@ea/common';
import { includes } from 'lodash-es';
import { Dict } from '../../../../../core/types';
import { FilterDefinition } from '../../../../../service/list/filters/typings';
import { SelectionByGroups } from '../../../../../service/list/selection/groups/typings';
import { extendSelection, shrinkSelection } from '../../../../../service/list/selection/groups/utils';
import { NormalizedList } from '../../../../../service/list/general/typings';
import { filterRows, joinRows, normalize } from '../../../../../service/list/general/utils';
import { PersonDetailsActions } from '../../../common/details/persons/actions';
import { PersonsActions } from '../../../common/entities/persons/actions';
import { SkillPersonsActions } from '../persons/actions';
import { TeamActions } from './actions';

interface Data extends NormalizedList<any> {
	changed: boolean;
}

export interface TeamState {
	data: PaginatedParametrizedContentLoading<Data>;
	filters: ParametrizedContentLoading<FilterDefinition[]>;
	selection: SelectionByGroups;
	assignCheckup: ParametrizedContentLoading<{
		spId?: number;
		allowed: boolean;
		persons: Record<string, string>;
	}>;
	lastParams: Dict;
}

const initialData = () => ({
	changed: false,
	groups: {},
	subgroups: {},
	items: {},
	rows: [],
});

const initialState = (): TeamState => ({
	data: initialPaginatedContentLoading(initialData(), {}),
	filters: initialContentLoading([], {}),
	selection: {
		rows: {},
		groups: {},
	},
	assignCheckup: initialContentLoading(
		{
			allowed: false,
			persons: {},
		},
		[],
	),
	lastParams: {},
});

export const TeamReducer = reducerWithInitialState(initialState())
	.case(TeamActions.getList.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(TeamActions.getList.done, (state, { result, params }) => ({
		...state,
		data: {
			content: joinRows(initialData(), normalize(result._embedded.groups || [])),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			pagination: {
				...result.page,
				more: result._embedded.groups.length > 0,
			},
			params,
		},
		scroll: 0,
	}))
	.case(TeamActions.getList.failed, (state, { params, error }) => ({
		...state,
		data: {
			...state.data,
			content: initialData(),
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
			params,
		},
	}))
	.case(TeamActions.loadMore.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				type: 'append',
				status: 'loading',
			},
		},
	}))
	.case(TeamActions.loadMore.done, (state, { result }) => ({
		...state,
		data: {
			...state.data,
			content: joinRows(state.data.content, normalize(result._embedded.groups || [])),
			loading: {
				type: 'append',
				status: 'loaded',
			},
			pagination: {
				...result.page,
				more: result._embedded.groups.length > 0,
			},
		},
	}))

	.case(TeamActions.getFilters.started, (state) => ({
		...state,
		filters: {
			...state.filters,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(TeamActions.getFilters.done, (state, { params, result }) => ({
		...state,
		filters: {
			content: result,
			loading: {
				status: 'loaded',
			},
			params,
		},
	}))
	.case(TeamActions.getFilters.failed, (state, { params, error }) => ({
		...state,
		filters: {
			content: [],
			loading: {
				status: 'loaded',
			},
			error,
			params,
		},
	}))
	.case(TeamActions.rememberParams, (state, lastParams) => ({
		...state,
		lastParams,
	}))
	.case(TeamActions.updateSelection, (state, data) => ({
		...state,
		selection: {
			...state.selection,
			...data,
		},
	}))
	.case(TeamActions.getStatistics.done, (state, { result }) => ({
		...state,
		selection: extendSelection(state.selection, result),
	}))
	.case(TeamActions.assignCheckup.started, (state, params) => ({
		...state,
		assignCheckup: {
			content: {
				allowed: false,
				persons: {},
			},
			params,
			loading: {
				status: 'loading',
			},
		},
	}))
	.case(TeamActions.assignCheckup.done, (state, { result }) => ({
		...state,
		assignCheckup: {
			...state.assignCheckup,
			content: result,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.case(TeamActions.assignCheckup.failed, (state, { error }) => ({
		...state,
		assignCheckup: {
			...state.assignCheckup,
			error,
			loading: {
				status: 'loaded',
			},
		},
	}))
	.cases([TeamActions.batchRemove.done, TeamActions.batchRestore.done], (state, { params }) => ({
		...state,
		data: {
			...state.data,
			content: {
				...state.data.content,
				rows: filterRows(state.data.content.rows, (row) => includes(params, row.id)),
			},
		},
		selection: shrinkSelection(state.selection, params),
	}))
	.cases(
		[
			SkillPersonsActions.batchRemove.done,
			SkillPersonsActions.batchRestore.done,
			PersonDetailsActions.update.done,
			PersonsActions.assignSkills.done,
		],
		(state) => ({
			...state,
			data: {
				...state.data,
				content: {
					...state.data.content,
					changed: true,
				},
			},
		}),
	);
