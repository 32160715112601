import React, { FC, useEffect } from 'react';
import { LoadingContent } from '@ea/common';
import { useDispatch, useSelector } from 'react-redux';
import { IThunkDispatch } from '../../../core/interfaces/store';
import { AuthSelectors } from '../../../store/general/auth/selectors';
import { AuthAsync } from '../../../store/general/auth/actionsSync';

export interface AuthorizationWrapperProps {}

export const AuthorizationWrapper: FC<AuthorizationWrapperProps> = ({ children }) => {
	const dispatch = useDispatch<IThunkDispatch>();
	const user = useSelector(AuthSelectors.user);
	const token = useSelector(AuthSelectors.token);

	const redirectToLogin = () => {
		const location = process.env.REACT_APP_AUTH_SERVICE;
		if (location) {
			window.location.href = location;
		}
	};

	useEffect(() => {
		if (!token) {
			redirectToLogin();
		}
	}, [dispatch, token]);
	return <LoadingContent loading={user.loading.status === 'loading'}>{children}</LoadingContent>;
};
