import React, { FC } from 'react';
import styled from 'astroturf';
import { ReactComponent as PulseIcon } from '../../images/indicators/pulse.svg';
import { HideableContent } from './HideableContent';

interface Props {
	loading?: unknown;
}

export const SmallLoading: FC<Props> = ({ loading, children }) => {
	return (
		<Wrapper>
			<SpinWrap visible={loading}>
				<StyledPulseIcon />
			</SpinWrap>
			<HideableContent visible={!loading}>{children}</HideableContent>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	display: inline-block;
`;

const SpinWrap = styled(HideableContent)`
	position: absolute;
	display: block;
	height: 24px;
	width: 24px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const StyledPulseIcon = styled(PulseIcon)`
	height: 24px;
	width: 24px;
`;
