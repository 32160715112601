import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { routes } from '../../../../core/routes/paths';
import { useWorkersProfile } from '../../../../hooks/workers/useWorkersProfile';

export const WorkersGuard: FC<RouteProps> = (props) => {
	const profile = useWorkersProfile();

	if (profile.isActive && (profile.isHr || profile.isAdmin || profile.isSuperAdmin)) {
		return <Route {...props} />;
	} else if (profile.loaded) {
		return <Redirect to={routes.root.path} />;
	} else {
		return null;
	}
};
