import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import { SkillDetail, SkillPosition, SkillTeam } from '../../../../../service/typings/workers/common/entities';
import { Row } from '../../../../../service/typings/lists';
import { SkillDetailsActions } from './actions';

export interface SkillDetailState {
	data: ContentLoading<SkillDetail | undefined>;
	positions: ContentLoading<SkillPosition[]>;
	persons: ContentLoading<Row[]>;
	trainings: ContentLoading<Row[]>;
}

const initialState: SkillDetailState = {
	data: initialContentLoading(undefined),
	positions: initialContentLoading([]),
	persons: initialContentLoading([]),
	trainings: initialContentLoading([]),
};

export const SkillDetailReducer = reducerWithInitialState(initialState)
	.case(SkillDetailsActions.getSkill.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				status: 'loading',
			},
		},
		positions: initialContentLoading([]),
	}))
	.case(SkillDetailsActions.getSkill.done, (state, { result }) => {
		return {
			...state,
			data: {
				...state.data,
				content: result,
				loading: {
					status: 'loaded',
				},
			},
		};
	})
	.case(SkillDetailsActions.getSkill.failed, (state) => {
		return {
			...state,
			data: {
				...state.data,
				content: undefined,
				loading: {
					status: 'loaded',
				},
			},
		};
	})
	.case(SkillDetailsActions.getPersons.started, (state) => ({
		...state,
		persons: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(SkillDetailsActions.getPersons.done, (state, { result, params }) => ({
		...state,
		persons: {
			content: normalizePersons(result),
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(SkillDetailsActions.getPersons.failed, (state, { params, error }) => ({
		...state,
		persons: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))
	.case(SkillDetailsActions.getTrainings.started, (state) => ({
		...state,
		trainings: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(SkillDetailsActions.getTrainings.done, (state, { result, params }) => ({
		...state,
		trainings: {
			content: result,
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(SkillDetailsActions.getTrainings.failed, (state, { params, error }) => ({
		...state,
		trainings: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}));

function normalizePersons(data: SkillTeam[]) {
	return data.reduce<Row[]>(
		(result, { persons, ...info }) => [
			...result,
			{
				section: true,
				...info,
			},
			...persons,
		],
		[],
	);
}
