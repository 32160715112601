import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { EmptyContent } from '@ea/common';
import { ProtectedRoute } from '../components/general/service/ProtectedRoute';
import AdminLayout from '../components/general/layout/AdminLayout';
import { MainRoute } from '../components/general/service/MainRoute';
import { routes } from '../core/routes/paths';
import { SpecialistGuard } from '../components/specialists/service/SpecialistGuard';
import { WorkersGuard } from '../components/workers/admin/service/WorkersGuard';
import { ForemanGuard } from '../components/workers/foreman/service/ForemanGuard';
import { Sites } from './common/Sites';
import { NotFound } from './common/NotFound';

const SpecialistsRoutes = lazy(() => import('./specialists/Routes'));
const WorkersRoutes = lazy(() => import('./workers/admin/Routes'));
const ForemanRoutes = lazy(() => import('./workers/foreman/Routes'));

export const Routes = () => {
	return (
		<Switch>
			<SpecialistGuard
				path={[
					routes.specialists.root.path,
					routes.specialists.library.root.path,
					routes.specialists.settings.root.path,
					routes.specialists.projects.root.path,
					routes.specialists.estimate.root.path,
				]}
			>
				<AdminLayout>
					<Suspense fallback={<EmptyContent />}>
						<SpecialistsRoutes />
					</Suspense>
				</AdminLayout>
			</SpecialistGuard>

			<ForemanGuard path={[routes.foreman.root.path]}>
				<AdminLayout>
					<Suspense fallback={<EmptyContent />}>
						<ForemanRoutes />
					</Suspense>
				</AdminLayout>
			</ForemanGuard>

			<WorkersGuard path={[routes.workers.root.path]}>
				<AdminLayout>
					<Suspense fallback={<EmptyContent />}>
						<WorkersRoutes />
					</Suspense>
				</AdminLayout>
			</WorkersGuard>

			<MainRoute>
				<AdminLayout>
					<Sites />
				</AdminLayout>
			</MainRoute>

			<ProtectedRoute path={'*'}>
				<NotFound />
			</ProtectedRoute>
		</Switch>
	);
};
