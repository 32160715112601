import { DateTime, Duration } from 'luxon';
import { fill, isString } from 'lodash-es';

export function plusMonths(date: string, months: number) {
	const dateTime = DateTime.fromISO(date);

	if (dateTime.isValid) {
		return dateTime.plus({ months });
	} else {
		return '';
	}
}

export function formattedDate(date: string | DateTime) {
	if (date) {
		const dateTime = isString(date) ? DateTime.fromISO(date) : date;
		if (dateTime.isValid) {
			return dateTime.toFormat('dd.MM.yyyy');
		}
	}
	return '';
}

export function formattedDateToISO(date: string) {
	if (date) {
		const dateTime = DateTime.fromFormat(date, 'dd.MM.yyyy');
		if (dateTime.isValid) {
			return dateTime.toISO();
		}
	}
	return '';
}

export function monthDays(month: DateTime, weekStart: number) {
	const reference = month.startOf('month');
	let firstDay = reference.weekday - weekStart;

	if (firstDay < 0) {
		firstDay += 7;
	}
	let lastDay = (weekStart - reference.weekday - reference.daysInMonth) % 7;
	if (lastDay < 0) {
		lastDay += 7;
	}

	return fill(Array(reference.daysInMonth + firstDay + lastDay), null).map((value, index) => {
		if (index < firstDay || index >= firstDay + reference.daysInMonth) {
			return null;
		} else {
			return reference.plus({ days: index - firstDay });
		}
	});
}
