import { History } from 'history';
import { AuthAsync } from './general/auth/actionsSync';

// tslint:disable-next-line:no-any
export const authorizeInterceptor = (history: History<any>, dispatch: any, status: number) => {
	if (status === 401 || status === 403) {
		dispatch(AuthAsync.logout());

		const redirectTo = process.env.REACT_APP_AUTH_SERVICE;
		if (redirectTo) {
			window.location.replace(redirectTo);
		}
	}
};
