import { useSelector } from 'react-redux';
import { AuthSelectors } from '../../store/general/auth/selectors';

export function useAuth() {
	const token = useSelector(AuthSelectors.token);
	const user = useSelector(AuthSelectors.user);

	const authorized = !!(token && (!user.content || user.content.roles.length > 0));

	return {
		authorized,
		user,
	};
}
