import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ContentLoading, initialContentLoading } from '@ea/common';
import {
	TrainingDetail,
	TrainingBibliography,
	TrainingPlan,
	TrainingProgram,
	TrainingFiles,
	TrainingAttribute,
} from '../../../../../service/typings/workers/common/entities';

import { TrainingDetailsActions } from './actions';

export interface TrainingDetailState {
	data: ContentLoading<TrainingDetail | undefined>;
	plan: ContentLoading<TrainingPlan[]>;
	bibliography: ContentLoading<TrainingBibliography[]>;
	attributes: ContentLoading<TrainingAttribute[]>;
	program: ContentLoading<TrainingProgram[]>;
	skills: ContentLoading<any>;
	files: ContentLoading<TrainingFiles[]>;
}

const initialState: TrainingDetailState = {
	data: initialContentLoading(undefined),
	plan: initialContentLoading([]),
	bibliography: initialContentLoading([]),
	attributes: initialContentLoading([]),
	program: initialContentLoading([]),
	skills: initialContentLoading([]),
	files: initialContentLoading([]),
};

export const TrainingDetailReducer = reducerWithInitialState(initialState)
	.case(TrainingDetailsActions.getTraining.started, (state) => ({
		...state,
		data: {
			...state.data,
			loading: {
				status: 'loading',
			},
		},
		program: initialContentLoading([]),
		bibliography: initialContentLoading([]),
		attributes: initialContentLoading([]),
		skills: initialContentLoading([]),
		plan: initialContentLoading([]),
		files: initialContentLoading([]),
	}))
	.case(TrainingDetailsActions.getTraining.done, (state, { result }) => {
		return {
			...state,
			data: {
				...state.data,
				content: result,
				loading: {
					status: 'loaded',
				},
			},
		};
	})
	.case(TrainingDetailsActions.getTraining.failed, (state) => {
		return {
			...state,
			data: {
				...state.data,
				content: undefined,
				loading: {
					status: 'loaded',
				},
			},
		};
	})

	.case(TrainingDetailsActions.getPlan.started, (state) => ({
		...state,
		plan: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(TrainingDetailsActions.getPlan.done, (state, { result, params }) => ({
		...state,
		plan: {
			content: result,
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(TrainingDetailsActions.getPlan.failed, (state, { params, error }) => ({
		...state,
		plan: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))

	.case(TrainingDetailsActions.getProgram.started, (state) => ({
		...state,
		program: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(TrainingDetailsActions.getProgram.done, (state, { result, params }) => ({
		...state,
		program: {
			content: result,
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(TrainingDetailsActions.getProgram.failed, (state, { params, error }) => ({
		...state,
		program: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))

	.case(TrainingDetailsActions.getBibliography.started, (state) => ({
		...state,
		bibliography: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(TrainingDetailsActions.getBibliography.done, (state, { result, params }) => ({
		...state,
		bibliography: {
			content: result,
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(TrainingDetailsActions.getBibliography.failed, (state, { params, error }) => ({
		...state,
		bibliography: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))

	.case(TrainingDetailsActions.getFiles.started, (state) => ({
		...state,
		files: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(TrainingDetailsActions.getFiles.done, (state, { result, params }) => ({
		...state,
		files: {
			content: result,
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(TrainingDetailsActions.getFiles.failed, (state, { params, error }) => ({
		...state,
		files: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}))

	.cases(
		[TrainingDetailsActions.getAttributes.started, TrainingDetailsActions.getDefaultAttributes.started],
		(state) => ({
			...state,
			attributes: {
				content: [],
				loading: {
					type: 'full',
					status: 'loading',
				},
			},
		}),
	)
	.cases(
		[TrainingDetailsActions.getAttributes.done, TrainingDetailsActions.getDefaultAttributes.done],
		(state, { result }) => ({
			...state,
			attributes: {
				content: result,
				loading: {
					type: 'full',
					status: 'loaded',
				},
			},
		}),
	)
	.cases(
		[TrainingDetailsActions.getAttributes.failed, TrainingDetailsActions.getDefaultAttributes.failed],
		(state, { params, error }) => ({
			...state,
			attributes: {
				content: [],
				loading: {
					type: 'full',
					status: 'loaded',
				},
				error,
			},
		}),
	)
	.case(TrainingDetailsActions.getSkills.started, (state) => ({
		...state,
		skills: {
			content: [],
			loading: {
				type: 'full',
				status: 'loading',
			},
		},
	}))
	.case(TrainingDetailsActions.getSkills.done, (state, { result }) => ({
		...state,
		skills: {
			content: result,
			loading: {
				type: 'full',
				status: 'loaded',
			},
		},
	}))
	.case(TrainingDetailsActions.getSkills.failed, (state, { params, error }) => ({
		...state,
		skills: {
			content: [],
			loading: {
				type: 'full',
				status: 'loaded',
			},
			error,
		},
	}));
