import { combineReducers } from 'redux';
import { AdminSkillsListReducer } from './skills/reducer';
import { PersonsListReducer } from './persons/reducer';
import { TrainingsListReducer } from './trainings/reducer';

export const ListsReducer = combineReducers({
	skills: AdminSkillsListReducer,
	persons: PersonsListReducer,
	trainings: TrainingsListReducer,
});
